import React from 'react';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./TestCompletedScreen.scss";
import Divider from "@mui/material/Divider";
import { useHistory } from 'react-router';
import TextField from '@mui/material/TextField';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import cogoToast from 'cogo-toast';
import { candidateFeedbackAPI, resendTest } from '../../../../services/prescreening';
import checkImg from "../../../../assets/images/check.svg";

const TestCompletedScreen = ({linkExpired, clientDetails}) =>{

  const history = useHistory();

  const [testDetails, setTestDetails] = useState([]);
  const [candidateRating,setCandidateRating] = useState();
  const [candidateComment,setCandidateComment] = useState("");
  const [testSubmitApiFail, setTestSubmitApiFail] = useState(false);
  const [resendEmailSent, setResendEmailSent] = useState(false);
  const testToken = JSON.parse(localStorage.getItem('testToken'));

  useEffect(() => {
    if(!linkExpired) {
      setTestDetails(history?.location?.state?.detail)
      setTestSubmitApiFail(history?.location?.state?.testSubmitApiFail)
      sessionStorage.removeItem('testToken');
    }
  },[])

  const RenderSuccessBox = () => {
    return (
      <>
             <Typography className='test-over-text black-colcor mt-2'> You have successfully completed the test.</Typography>
             <Typography className='text mt-3'>Thank you for taking the test.</Typography>
             <Typography className='text bt-10'>The <span className='bold'>{testDetails?.client_name}</span> Team will reach out to you for further process, incase you are shortlisted.</Typography>
      </>
    )
  }

  const RenderContactBox = () => {
    return (
        <>
           <Divider />
           <Typography className='text mt-50'>In case of any inconvinience or queries, feel free to reach out to us at <a href="https://interviewvector.com/">ops@interviewvector.com</a> </Typography>
        </>
    )
}

const getTotalRating = () => {
  return Array(10).fill(1).map((n, i) => n + i);
}

const handleRatingChange = (rating) => {
  setCandidateRating(rating);
}

const handleCommentChange = (e) => {
  setCandidateComment(e?.target?.value);
}

const handleWindowClose = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

const handleSubmit = () => {
  if(!candidateRating)
  {
    cogoToast?.error("Please rate your overall experience",{position:"top-right"});
    return;
  }
  if(!testToken){
    cogoToast?.error("There is an error in fetching your token. Please try again.",{position:"top-right"});
    return;
  }
  const payload = {
    "candidate_rating":candidateRating,
    "candidate_feedback":candidateComment,
  }
  candidateFeedbackAPI(payload,testToken).then(res=>{
    cogoToast.success("Your feedback has been submitted successfully",{position:"top-right"});
    setTimeout(()=>{
      localStorage.removeItem("testToken");
      handleWindowClose();
    },[1000])
  }).catch(error=>{
    cogoToast?.error(error?.data?.msg?.toString() || error?.data?.toString() || error?.toString() || "Feedback could not be saved",{position:"top-right"});
  });
}

const handleResendTest = async() => {
  try{
   const payload = {
    // Here we don't have id because token is expired, so we are sharing token to BE and it will handle this acc.
     candidate_pre_screening_round_id: '',
    }
   const testResend = await resendTest(testToken, payload);

   if (testResend?.status === 200){
    setResendEmailSent(true);
  } else {
    cogoToast.error(testResend?.data || testResend?.data?.msg || "Failed to resend test!")
  }
} catch(err) {
  cogoToast.error(err?.data?.msg || err?.data || "Failed to resend test!");
}
}

const RenderFeedbackBox = () => {
  return (    <Box className='box-2'>
  <Box className='experience-text-box'>
      <Typography className='text'>Tell us about your test experience 🙌🏻</Typography>
  </Box>
  <Box display="flex" flexDirection="column" gap="8px">
      <Typography className='overall-text'>How would you rate the <span className='bold-600'>overall test experience?</span> <span className='star'>*</span></Typography>
      <Box className='rating-box'>
        {
          getTotalRating()?.map((item,index)=>{
            return (
              <>
               <Box className={`rating ${item===candidateRating && "selected-rating"}`}
                onClick={()=>handleRatingChange(item)}>{item}</Box>
              </>
            )
          })
        }
      </Box>
  </Box>

  <Box className='share-container'>
      <Typography className='share-text'>Share the details:</Typography>
      <TextField 
        className='input'
        size='small'
        multiline
        fullWidth
        minRows={2}
        onChange={handleCommentChange}
        value={candidateComment}
        sx={{
          padding:"0px",
          '& legend':{
            display:'none'
          }
        }}
      />
  </Box>
  <Box>
      <Button 
        className='submit'
        onClick={handleSubmit}
        >
          Submit&nbsp;&nbsp;
          <ArrowForwardIcon fontSize='small'/>
      </Button>
  </Box>
</Box>)
}

  return (
    <Box className={`testCompleted-screen-parent ${!linkExpired ? 'main-success-box':''}`}>  
    <Box className='box-1'>
    {linkExpired ? 
      <>
        <Typography className='link-expired'>Sorry, the test you are trying to access has expired.</Typography>
        <Box className='mt-50'>
          <Typography className='client-details'>Please contact the <span className='fw-600'>{clientDetails?.client_name}</span> HR, {clientDetails?.hr_name && <><span className='fw-600'>{clientDetails?.hr_name}</span>, at</>}</Typography>
          <Typography className='client-details'>{clientDetails?.hr_email && <><span className='fw-600'>{clientDetails?.hr_email}</span>,</>} for more information.</Typography>
          </Box>
        {resendEmailSent &&
          <Box className='email-sent-text'>
            <img src={checkImg} alt='check-img' className='mt-2 bt-10'/>
            <Typography className='email-sent-text'>Test mail link has been successfully resent. Please check your </Typography>
            <Typography className='email-sent-text'>email inbox, including spam/junk folders, for the new link.</Typography>
          </Box>
        }
        <Box>
          <Button className={`close-btn ${resendEmailSent ? "" : "ml-150"}`} onClick={() => handleWindowClose()}>OK! CLOSE TAB</Button>
          {!resendEmailSent && <Button className='resend-btn' onClick={handleResendTest}>RESEND TEST LINK</Button>}
        </Box>
      </> :
      testSubmitApiFail ?  
      <>
        <Typography className='test-over-text blue-color'>Oops!</Typography>
        <Typography className='test-over-text black-colcor mt-2 marginBottom-3'> The test hasn't been submitted.</Typography>
      </> :  
      RenderSuccessBox()
    }     
      {RenderContactBox()}
    </Box>
      {!linkExpired ? RenderFeedbackBox() : ""}
    </Box>
  )
}

export default TestCompletedScreen;